<template>
  <BaseModal
    ref="modal"
    :title="$t('b2b__support_modal__not_eligible__title')"
  >
    <template #trigger="{ open }">
      <BaseButton
        variant="outlined"
        data-qa="request-support-modal-trigger"
        size="small"
        @click="open"
      >
        {{ $t('b2b__verification__not_eligible_error_cta_get_help') }}
      </BaseButton>
    </template>
    <div class="request-support-modal kds-content-block">
      <BaseBanner
        v-if="requestSupportApiError"
        variant="warning"
        :title="$t('b2b__support_modal_generic__error_banner__title')"
        :description="
          $t('b2b__support_modal_generic__error_banner__description')
        "
      >
      </BaseBanner>
      <p class="kds-typography-title-medium">
        {{
          $t('b2b__support_modal__not_eligible__eligible_info_section_title')
        }}
      </p>
      <ul class="request-support-modal__additional_data">
        <slot></slot>
      </ul>
    </div>
    <template #footer>
      <form class="request-support-modal__form">
        <p class="kds-typography-title-medium">
          {{
            $t('b2b__support_modal__not_eligible__form_employer_section_title')
          }}
        </p>
        <TextField
          v-bind="fieldAttrs.userEnteredEmployer"
          data-qa="request-support-modal-corporate-info"
          :label="$t('b2b__support_modal__not_eligible__form_label_employer')"
        />
        <p class="kds-typography-title-medium">
          {{
            $t('b2b__support_modal__not_eligible__form_contact_section_title')
          }}
        </p>
        <TextField
          v-bind="fieldAttrs.email"
          autocomplete="email"
          data-qa="request-support-modal-email"
          type="email"
          :label="$t('b2b__support_modal__not_eligible__form_label_email')"
        />
        <BaseButton
          v-bind="submitAttrs"
          class="request-support-modal__submit"
          data-qa="request-support-modal__submit"
        >
          {{ $t('b2b__support_modal__not_eligible__form_submit') }}
        </BaseButton>
      </form>
    </template>
  </BaseModal>
  <BaseModal
    ref="supportTicketCreationSuccessModal"
    @close="emit('submit-success')"
  >
    <template #header>
      <CheckIcon class="success-submit-support-modal--header--check-icon" />
    </template>
    <p
      class="kds-typography-paragraph-large success-submit-support-modal--body--message"
    >
      {{ $t('b2b__support_modal_generic__success_modal_title') }}
    </p>
  </BaseModal>
</template>

<script setup>
import { ref, computed } from 'vue'

import { submitSupportTicket } from '@shared/api-endpoints'
import { isFirstNameKey, isLastNameKey } from '@shared/utils'
import { getEligibilitySessionHash } from '@us/app/state/store'

import useForm from '@shared/composables/useForm.js'
import useVueSlots from '@shared/composables/useVueSlots.js'

import BaseBanner from '@shared/components/BaseBanner.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import BaseModal from '@shared/components/BaseModal.vue'
import TextField from '@shared/components/form/TextField.vue'
import CheckIcon from '@shared/assets/icon/CheckIcon.vue'

const modal = ref()
const supportTicketCreationSuccessModal = ref(null)
const requestSupportApiError = ref(false)

const emit = defineEmits(['submit-success'])
const props = defineProps({
  additionalData: {
    type: Object,
    default: () => ({}),
  },
  corporate: {
    type: Object,
    default: () => ({}),
  },
})

const { hasSlot } = useVueSlots(['default'])

const firstName = computed(() => {
  const verificationFieldKeys = Object.keys(props.additionalData)

  const firstNameKey = verificationFieldKeys.find(isFirstNameKey)
  return (firstNameKey && props.additionalData[firstNameKey]) || ''
})

const lastName = computed(() => {
  const verificationFieldKeys = Object.keys(props.additionalData)

  const lastNameKey = verificationFieldKeys.find(isLastNameKey)
  return (lastNameKey && props.additionalData[lastNameKey]) || ''
})

const name = computed(() => {
  return [firstName.value, lastName.value].join(' ') || 'Unknown name'
})

const form = ref({
  userEnteredEmployer: '',
  email: '',
})

const triggerAutofocus = ref(false)

const { fieldAttrs, submitAttrs } = useForm(
  form,
  ref({
    userEnteredEmployer: { validations: [] },
    email: { validations: ['required', 'email'] },
  }),
  {
    onSubmit: async () => {
      requestSupportApiError.value = false

      await submitSupportTicket({
        email: form.value.email,
        name: name.value,
        first_name: firstName.value,
        last_name: lastName.value,
        subject: 'Support with Kaia Eligibility',
        text: "User requested help. System can't find the member in our eligibility files.",
        additional_data: {
          eligibility_session: getEligibilitySessionHash(),
          corporate: props.corporate.id,
          employer_entered_by_user: form.value.userEnteredEmployer,
          ...props.additionalData,
        },
      })
    },
    onSuccess: () => {
      modal.value.close()
      supportTicketCreationSuccessModal.value.open()
    },
    onError: () => {
      requestSupportApiError.value = true
    },
  },
  triggerAutofocus,
)
</script>

<style lang="scss" scoped>
.request-support-modal {
  &__additional_data {
    @include flex-container(column, flex-start, md);
    margin: 0;
    padding-left: 20px;
  }

  &__form {
    @include flex-container(column);
  }

  &__submit {
    width: 100%;
  }
}

[data-qa='request-support-modal-trigger'] {
  @include margin(md, top);
}

.success-submit-support-modal {
  &--header--check-icon {
    margin: auto;
    height: 48px;
    width: 48px;
  }

  &--body--message {
    text-align: center;
  }
}
</style>
