<template>
  <InputWrapper
    :errors="errors"
    :has-errors="hasErrors"
    :hint="hint"
    :focused="isFocused"
    :shrink-label="isFocused || !!model.length"
  >
    <template
      v-if="hasSlot['prepend']"
      #prepend
    >
      <slot name="prepend" />
    </template>
    <template #label>{{ label }}</template>
    <input
      :id="name"
      ref="inputRef"
      v-model="model"
      :data-qa="dataQa"
      :name="name"
      class="text-field"
      autofocus="autofocus"
      v-bind="{
        type: 'text',
        ...$attrs,
        placeholder: isFocused ? $attrs.placeholder : '',
      }"
      @blur="onBlur"
      @focus="onFocus"
      @input="(e) => emit('input', e)"
      @keypress="(e) => emit('keypress', e)"
    />
    <template
      v-if="hasSlot['append']"
      #append
    >
      <slot name="append" />
    </template>
  </InputWrapper>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
import InputWrapper from './InputWrapper.vue'
import useVueSlots from '@shared/composables/useVueSlots.js'

const { hasSlot } = useVueSlots(['prepend', 'append'])
const inputRef = ref(null)
const emit = defineEmits(['input', 'blur', 'keypress', 'focus'])

defineOptions({
  inheritAttrs: false,
})

const model = defineModel({
  type: String,
  default: '',
})

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  errors: {
    type: Array,
    default: () => [],
  },
  hasErrors: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    default: '',
  },
  dataQa: {
    type: String,
    default: '',
  },
  hint: {
    type: String,
    default: '',
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
})

const isFocused = ref(false)

const onBlur = (e) => {
  isFocused.value = false
  emit('blur', e)
}

const onFocus = (e) => {
  isFocused.value = true
  emit('focus', e)
}

watchEffect(() => {
  if (props.autofocus) inputRef.value?.focus()
})

defineExpose({ input: inputRef })
</script>

<style lang="scss">
.wrapper {
  &__container {
    @include grid-container(1fr, flex-start, 0);

    &--field {
      &--input {
        .text-field {
          @include kds-typography-interactive-medium;
          position: relative;
          appearance: none;
          background-color: transparent;
          border: 0;
          color: $kds-color-text-primary;
          outline: 0;
          width: 100%;
          box-sizing: border-box;
          // this equals to padding-top of 20, line-height of 22, and
          // padding-bottom of 4 but when clicking on the top padding the
          // cursor is set to the rigth position in the text field instead
          // of to the start
          line-height: 62px;
          padding: 0 16px 0 16px;
          margin-bottom: -16px;

          &:-webkit-autofill {
            animation-name: onAutoFillStart;
          }

          &:not(:-webkit-autofill) {
            animation-name: onAutoFillCancel;
          }

          &::placeholder {
            color: $kds-color-text-tertiary;
            font-weight: 500;
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
