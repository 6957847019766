import { ref, computed, watchEffect, watch } from 'vue'
import {
  dob,
  email,
  password,
  phone,
  required,
  accepted,
} from '@shared/helpers/validations.js'
import { supportsTouch, wait } from '@shared/utils.js'

/**
 * Returns a random id to identify form fields (using crypto if available)
 * @returns {string}
 */
const getRandomId = () => {
  // INFO: This is a fallback for browsers that do not support window.crypto.randomUUID
  // taken from https://stackoverflow.com/questions/105034/how-do-i-create-a-guid-uuid/2117523#2117523
  // (doesn't need to be secure or fast, just need to be unique)
  if (!window.crypto.randomUUID) {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16),
    )
  }

  return window.crypto.randomUUID()
}

/**
 * useForm composable is the preferred method to build forms.
 *
 * @typedef Ref<{[key: string]: unknown}> State
 *
 * @param { Ref<State> } state Form object
 * An object within a component or store
 *
 * @param { Ref<Object.<keyof State, { validations: string[] }>>} config Contains validations for the state keys
 * Currently the configuration only receive validations string array, but can be expanded for more functionality
 *
 * @param {{
 *   onSubmit: () => Promise<void> | void,
 *   onSuccess: () => Promise<void> | void,
 *   onError: (any) => Promise<void> | void
 * }} callbacks to run after appropriate events
 * onSubmit: Run after all validations pass
 * onSuccess: Runs after onSubmit is successful
 * onError: Runs if onSubmit fails
 * onFinally: Runs last everytime
 *
 * @param { Ref<boolean> } [triggerAutofocus = ref(true)] whether to trigger the autofocus on the first unfilled field
 *
 * @returns {{
 *   fieldAttrs: Ref<{
 *     modelValue: State[key],
 *     'onUpdate:modelValue': () => void,
 *     name: string,
 *     disabled: Boolean,
 *     autofocus: Boolean,
 *     enterkeyhind: String,
 *     errors: String[],
 *     onInput: () => void,
 *     onFocus: () => void,
 *     onBlur: () => void,
 *   }>,
 *   submitAttrs: Ref<{
 *     type: 'submit',
 *     onClick: () => Promise<void>,
 *     loading: boolean,
 *     success: boolean,
 *   }>,
 *   submit: () => Promise<void>,
 *   addErrors: (fieldKey: string, ...fieldErrors: string[]) => void
 * }}
 * fieldAttrs: Object which returns parameters and event listeners which can be bound to the form field components (see story for reference)
 * submitAttrs: Object which returns parameters and event listeners which can be bound to the form submit component (see story for reference)
 * submit: Function that will submit the form
 *
 * Tests: tests/unit/shared/composables/useForm.spec.js
 * Stories: stories/form/useForm.story.vue
 */
export default function useForm(
  state,
  config,
  callbacks,
  triggerAutofocus = ref(true),
) {
  const { onSubmit, onSuccess, onError, onFinally = () => {} } = callbacks

  const formId = getRandomId()
  const errors = ref({})
  const loading = ref(false)
  const success = ref(false)
  const autofocusField = ref(null)

  // reset errors whenever config updates
  watchEffect(() => {
    Object.keys(config.value).forEach((fieldKey) => {
      errors.value[fieldKey] = []
    })
  })

  // select the first field that does not have a value to be autofocused (only on desktop)
  watch(
    [config, triggerAutofocus],
    () => {
      autofocusField.value =
        triggerAutofocus.value &&
        !supportsTouch() &&
        Object.keys(config.value).find(
          (fieldKey) =>
            !state.value[fieldKey]?.length || !state.value[fieldKey],
        )
    },
    { immediate: true },
  )

  const addErrors = (fieldKey, ...fieldErrors) => {
    errors.value[fieldKey].push(
      ...fieldErrors.filter((fieldError) => !!fieldError),
    )
  }

  /**
   * @param {string} fieldKey
   * @param {any} opts Options for validation that could be unique for each type of validation
   * @returns {boolean} isValid
   */
  const validateField = (fieldKey, opts) => {
    errors.value[fieldKey] = []

    config?.value?.[fieldKey]?.validations?.forEach((rule) => {
      const [validation] = rule.split(':')
      //const [validation, attribute] = rule.split(':') INFO: Use this when we do end up needing 'attribute'
      const value = state.value[fieldKey]
      switch (validation) {
        case 'required':
          addErrors(fieldKey, required(value))
          break
        case 'dob': {
          addErrors(fieldKey, ...dob(value, opts))
          break
        }
        case 'password':
          addErrors(fieldKey, ...password(value))
          break
        case 'email':
          addErrors(fieldKey, email(value))
          break
        case 'phone':
          addErrors(fieldKey, phone(value, opts))
          break
        case 'accepted':
          addErrors(fieldKey, accepted(value))
          break
      }
    })

    return errors.value[fieldKey].length === 0
  }

  /**
   * Function that validates all inputs at once and forces validation where applicable
   * @return {boolean} isAllValid
   */
  const validateAll = () => {
    return Object.keys(config.value)
      .map((fieldKey) =>
        validateField(fieldKey, {
          ...config.value[fieldKey]?.defaults,
          force: true,
        }),
      )
      .every((isValid) => isValid)
  }

  const handleInput = (fieldKey, opts) => {
    if (errors.value[fieldKey].length > 0) validateField(fieldKey, opts)
  }

  const handleBlur = (fieldKey, opts) => {
    validateField(fieldKey, opts)
  }

  const handleFocus = () => {
    return true
  }

  /*
  const handleKeyDown = (fieldKey, event) => {
    if (event.key === 'Enter') {
      const formElements = Object.keys(config.value)
      const index = formElements.indexOf(fieldKey)

      // Check if Enter key (Next) was pressed on input that is not the last one
      if (index >= 0 && index < formElements.length - 1) {
        // Prevent form submission
        event.preventDefault()

        // Focus next input field
        document
          .querySelector(`[name="${formId}:${formElements[index + 1]}"]`)
          ?.focus()
      }
    }
  }
  */

  const fieldAttrs = computed(() =>
    Object.fromEntries(
      Object.keys(config.value).map((fieldKey, index) => [
        fieldKey,
        {
          modelValue: state.value[fieldKey],
          'onUpdate:modelValue': (value) => (state.value[fieldKey] = value),
          name: `${formId}:${fieldKey}`,
          disabled: loading.value || success.value,
          autofocus: autofocusField.value === fieldKey,
          errors: errors.value[fieldKey],
          // overwrite the virtual keboards enter text for all but the last input
          enterkeyhint:
            index >= 0 && index < config.value.length - 1 ? 'next' : null,
          onInput: (e) => handleInput(fieldKey, e),
          onFocus: (e) => handleFocus(fieldKey, e),
          onBlur: (e) => handleBlur(fieldKey, e),
          // TODO test this thoroughly and then enable it
          //onkeydown: (e) => handleKeyDown(fieldKey, e),
        },
      ]),
    ),
  )

  const scrollToFirstInvalidField = () => {
    const scrollToFieldKey = Object.keys(config.value).find(
      (fieldKey) => errors.value[fieldKey].length > 0,
    )
    const scrollToField = document.querySelector(
      `[name="${formId}:${scrollToFieldKey}"]`,
    )
    if (scrollToFieldKey === undefined || scrollToField === null) return
    window.scrollTo({
      top: scrollToField.getBoundingClientRect().top + window.scrollY - 16,
      behavior: 'smooth',
    })
  }

  const submit = async () => {
    if (!validateAll()) {
      scrollToFirstInvalidField()
      return
    }

    try {
      loading.value = true
      await onSubmit()
      success.value = true
      loading.value = false
      await wait(2000)
      await onSuccess()
    } catch (error) {
      onError(error)
    } finally {
      loading.value = false
      await onFinally()
    }
  }

  const submitAttrs = computed(() => ({
    type: 'submit',
    onClick: submit,
    loading: loading.value,
    success: success.value,
  }))

  return {
    addErrors,
    fieldAttrs,
    submitAttrs,
    submit,
  }
}
